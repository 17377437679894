import React, { Component } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { formListService } from '../services/form-list.service';
import IconButton from '@material-ui/core/IconButton';
import { Button, FormControl, InputLabel, Select, Toolbar, Typography, withStyles, Grid, TextField, Box } from '@material-ui/core';
import { categoryService } from '../services/category-state.service';
import { language } from '../services/form-list.service';
import SearchDialog from './form-element/SearchDialog';
import { DEFAULT_LANGUAGE_LIST } from '../constants/Constants';
import { formTypeState } from '../services/form.store';

const styles = theme => ({
    underline: {
        '&:before': {
            borderBottom: "1px solid #419BAC !important"
        }
    },
    formLabel: {
        padding: "0px 5px",
        color: "#747a84"
    },
    formSearch:{
        paddingBottom: "10px",
        color: "#747a84"
    },
    toolBar: {
        bottom: 0,
        top: 'auto',
        position: 'relative',
        width: '100%'
    },
    flexGrow: {
        flexGrow: 1
    },
    formGroup: {
        marginTop: '10px'
    },
    customSelectLabel: {
        top: '-7px',
        left: '15px'
    }
})

const formValues = {
    business: '',
    subbusiness: '',
    group: '',
    subgroup: ''
};

class SearchByFormName extends Component {
    constructor(props) {
        super();
        this.timeout = 0;
        this.state = {
            showSearch: false,
            business: [],
            subbusiness: [],
            group: [],
            subgroup: [],
            languages: [],
            formvalues: formValues,
            formCategories: [],
            selectedCategories: [],
            searchType: 'Form Name',
            defaultLanguage: '',
            anchorEl: null,
            searchKey: 'name',
            searchText: ''
        }
    }

    componentDidMount() {
        this.subscription = categoryService.didChangeCategory().subscribe(response => {
            for (var key in response) {
                this.updateCategory(key, response[key]);
            }
        });
        this.languageSubscription = language.subscribe((response) => {
            this.setState({ languages: response })
        });
        categoryService.initializeCategories();
        this.formTypeSubscription = formTypeState.subscribe(formType => {
            this.setState({
              formType,
            });
        });
    }

    componentDidUpdate(prevProps, prevState){
        if (prevState.formType !== this.state.formType) {
            this.formResetHandler();
        }
    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
        this.languageSubscription.unsubscribe();
    }

    categoryChangeHandler = event => {
        const name = event.target.name;
        const value = event.target.value;
        this.updateFormValue(name, value);
        categoryService.selectCategory(name, value);
    }

    updateFormValue = (name, value) => {
        this.setState({
            formvalues: {
                ...this.state.formvalues, [name]: value
            }
        });
    }

    updateCategory = (name, categoryInfo) => {
        const options = categoryInfo['options'];
        this.setState({
            [name]: options
        });

        if (options.length && this.state.selectedCategories.some(item => item.hasOwnProperty(name))) {
            let selected = [...this.state.selectedCategories];
            let index = selected.findIndex(item => item.hasOwnProperty(name));
            selected[index][name] = true;
            this.setState({ selectedCategories: selected });
        }

        const value = categoryInfo['value'];
        if (value !== undefined) {
            this.updateFormValue(name, value);
        }
    }

    handleSearchClick = (e) => {
        this.setState({ showSearch: !this.state.showSearch ,anchorEl:e.currentTarget});
    }

    startTimerForSearch(value) {
        if (value.length === 0) {
            formListService.clearSearch();
            formListService.loadAllForms();
        }
        this.setState({
            searchText: value
        });
    }

    searchTypeHandler = (e) => {
        const selectedOptionName = e.target.selectedOptions[0].getAttribute('name');
        this.setState({
            searchType: e.target.value,
            searchKey: selectedOptionName
        });
    }

    formSubmitHandler = () => {
        const { business, subbusiness, group, subgroup  } = this.state.formvalues;
        if (this.state.searchText.length === 0 && !business && !subbusiness && !group && !subgroup) {
            formListService.loadAllForms();
        } else {
            var payload = {};
            if (this.state.searchKey) {
                if (this.state.searchKey === DEFAULT_LANGUAGE_LIST) {
                    const languages = this.state.languages?.reduce((acc, value) => {
                        if (value?.name.toLowerCase().includes(this.state.searchText.toLowerCase().trim())) {
                            acc.push(value?.languageCode);
                        }
                        return acc;
                    }, []);
                    if (languages.length) {
                        payload[this.state.searchKey] = languages;
                    } else {
                        payload[this.state.searchKey] = [null];
                    }
                } else if (this.state.searchText) {
                    payload[this.state.searchKey] = this.state.searchText;
                }
            }
            let formCategories = []
            if (business) formCategories.push(business);
            if (subbusiness) formCategories.push(subbusiness);
            if (group) formCategories.push(group);
            if (subgroup) formCategories.push(subgroup);
            if (formCategories) {
                if (formCategories.length > 0) {
                    payload.formCategories = formCategories;
                } else {
                    payload.formCategories = [];
                }
            }
            const userSSO = (localStorage.getItem('ge_sso') && this.state.formType === "my") ? localStorage.getItem('ge_sso') : '';
            formListService.searchForms(payload, userSSO);
        }
        this.setState({ anchorEl: false });
    }
 
    formResetHandler = () => {
        this.setState({
            business: [],
            subbusiness: [],
            group: [],
            subgroup: [],
            searchText: '',
            defaultLanguage: '',
            formvalues: formValues,
            anchorEl: false
        });
        categoryService.initializeCategories();
        formListService.clearSearch();
        formListService.loadAllForms();  
    }

    handleClearSearch = () => {
        this.setState({
            business: [],
            subbusiness: [],
            group: [],
            subgroup: [],
            searchText: '',
            defaultLanguage: '',
            formvalues: formValues,
            searchType: 'Form Name',
            searchKey: 'name'
        });
        categoryService.initializeCategories();
        formListService.clearSearch();
        formListService.loadAllForms();
    }

    handleClose = () => {
        this.setState({ anchorEl: false })
    };

    render() {
        const { classes } = this.props;
            return (
                <div>
                <IconButton id="searchButton" onClick={this.handleSearchClick}><SearchIcon /></IconButton>
                    <SearchDialog
                        open={Boolean(this.state.anchorEl)}
                        onCloseMenu={this.handleClose}
                        anchorEl={this.state.anchorEl}
                        name = "advanceSearch"
                        title=""
                        description="">
                        <form autoComplete="off" noValidate>
                            <Box>
                                <Grid container spacing={3}>
                                    <Grid item md={12} xs={12}>
                                        <TextField className={classes.formSearch}
                                            fullWidth
                                            id="search"
                                            name="search"
                                            label={"Enter " + this.state.searchType}
                                            autoComplete='off'
                                            value={this.state.searchText}
                                            onChange={event => {
                                                this.startTimerForSearch(event.target.value)
                                            }}>
                                        </TextField>
                                    </Grid>
                                </Grid> 
                                <Grid container spacing={3} >
                                    <Grid item md={12} xs={12}>
                                        <Typography className={classes.formLabel} variant="inherit">SEARCH BY:</Typography>
                                        <FormControl fullWidth className={classes.formGroup}>
                                            <InputLabel htmlFor="checklist" className={classes.customSelectLabel}>Select Category</InputLabel>
                                            <Select
                                                native
                                                fullWidth
                                                id="checklist"
                                                variant="outlined"
                                                label="Select Category"
                                                name="searchBy"
                                                defaultValue=""
                                                displayEmpty
                                                value={this.state.searchType}
                                                onChange={this.searchTypeHandler}
                                                >
                                                <option name="name" value="Form Name">Form Name</option>
                                                <option name="createdByName" value="Owner">Owner</option>
                                                <option name="defaultLanguageList" value="Language">Language</option>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box>
                                <Grid container spacing={3} >
                                    <Grid item md={12} xs={12}>
                                        <Typography className={classes.formLabel} variant="inherit">FILTER BY:</Typography>
                                        <FormControl fullWidth className={classes.formGroup}>
                                            <InputLabel htmlFor="filterByCategory" className={classes.customSelectLabel}> Business</InputLabel>
                                            <Select
                                                native
                                                fullWidth
                                                variant="outlined"
                                                id="filterByCategory"
                                                label="Business"
                                                name="business"
                                                defaultValue=""
                                                displayEmpty
                                                value={this.state.formvalues.business}
                                                onChange={this.categoryChangeHandler}
                                            >
                                            <option value="" disabled style={{ display: "none" }}></option>
                                            {this.state.business.map((opt) => (
                                                <option key={opt.categoryName} value={opt.categoryName} >
                                                    {opt.categoryName}
                                                </option>
                                            ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box>
                                <Grid container spacing={3} >
                                    <Grid item md={12} xs={12}>
                                        <FormControl fullWidth className={classes.formGroup}>
                                            <InputLabel htmlFor="filterBySubbusiness" className={classes.customSelectLabel}>Sub-Business</InputLabel>
                                            <Select
                                                native
                                                fullWidth
                                                id="filterBySubbusiness"
                                                label="Sub-Business"
                                                name="subbusiness"
                                                variant="outlined"
                                                value={this.state.formvalues.subbusiness}
                                                defaultValue=""
                                                displayEmpty
                                                onChange={this.categoryChangeHandler}
                                                disabled={!this.state.formvalues.business.length}
                                            >
                                                <option value="" disabled style={{ display: "none" }}></option>
                                                {this.state.subbusiness.map((opt) => (
                                                <option key={opt.categoryName} value={opt.categoryName}>
                                                    {opt.categoryName}
                                                </option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box>
                                <Grid container spacing={3} >
                                    <Grid item md={12} xs={12}>
                                        <FormControl fullWidth className={classes.formGroup}>
                                            <InputLabel htmlFor="filterByGroup" className={classes.customSelectLabel}>Group</InputLabel>
                                            <Select
                                                native
                                                select
                                                fullWidth
                                                defaultValue=""
                                                displayEmpty
                                                id="filterByGroup"
                                                label="Group"
                                                name="group"
                                                variant="outlined"
                                                value={this.state.formvalues.group}
                                                onChange={this.categoryChangeHandler}
                                                disabled={!this.state.formvalues.subbusiness.length}
                                            >
                                                <option value="" disabled style={{ display: "none" }}></option>
                                                {this.state.group.map((opt) => (
                                                <option key={opt.categoryName} value={opt.categoryName}>
                                                    {opt.categoryName}
                                                </option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box>
                                <Grid container spacing={3} >
                                    <Grid item md={12} xs={12}>
                                        <FormControl fullWidth className={classes.formGroup}>
                                            <InputLabel htmlFor="filterBySubGroup" className={classes.customSelectLabel}>Sub-Group</InputLabel>
                                            <Select
                                                native
                                                fullWidth
                                                defaultValue=""
                                                displayEmpty
                                                id="filterBySubGroup"
                                                label="Sub-Group"
                                                name="subgroup"
                                                variant="outlined"
                                                value={this.state.formvalues.subgroup}
                                                onChange={this.categoryChangeHandler}
                                                disabled={!this.state.formvalues.group.length}>
                                                <option value="" disabled style={{display: 'none'}}></option>
                                                {this.state.subgroup.map((opt) => (
                                                    <option key={opt.categoryName} value={opt.categoryName}>
                                                        {opt.categoryName}
                                                    </option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Toolbar className={classes.toolBar}>
                                <span className={classes.flexGrow} />
                                <Button id="reset" variant="outlined" color='secondary' onClick={this.handleClearSearch} disableElevation >Clear</Button>
                                <Button id="apply" variant="contained" disableElevation onClick={this.formSubmitHandler}>Apply</Button>
                            </Toolbar>
                        </form>
                    </SearchDialog>
                </div>
            );
    }
}

export default withStyles(styles)(SearchByFormName);