import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    uppercase:{
      textTransform: 'uppercase'
    },
    title:{
      paddingBottom: "5px",
    },
    delete:{
      border: '1px solid #FE1919', 
    color:"white",
    backgroundColor:"#FE1919",
    '&:hover': { 
      backgroundColor: '#FE1919' 
    } 
    },
    paper: { 
      maxWidth: "200px",
      border: "1px solid rgba(0, 0, 0, 0.24)",
    },
    divider:{
      marginLeft:"24px"
    }
}));

export default function ConfirmationDialog(props) {
  const classes = useStyles();
  const { onClose, open, onDelete, description, title, anchorEl} = props;
 
  const handleCancel = () => {
    onClose();
  };

  const handleDelete = () => {
    onDelete();
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="bottom-end"
      className={classes.popper}
    >
      <Paper className={classes.paper}>
        <DialogTitle id="alert-dialog-title" className={`${classes.uppercase} ${classes.title}`} disableTypography >{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {description}
          </DialogContentText>
        </DialogContent>
        <Divider  className={classes.divider} />
        <DialogActions>
          <Button onClick={handleCancel} variant='outlined' color='secondary' className={classes.uppercase}>
            Cancel
          </Button>
          <Button onClick={handleDelete} variant="contained" color="primary" className={`${classes.uppercase} ${classes.delete}`} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Paper>
    </Popper>
  );
}

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
