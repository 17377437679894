import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { formListService } from "../services/form-list.service";
import UniversalSelector from "./UniversalSelector";
import { NO, YES } from "../utility/Constant";

const ConditionalForm = ({createFormClicked, onValidation, updateForm, copyForm}) => {
  const useStyles = makeStyles(() => ({
    spacing: {
      display: "flex",
      alignItems: "center",
      marginTop: "12px",
      marginBottom: "8px",
      marginLeft: "4px",
    },
    condition: {
      marginRight: "24px",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
    },
  }));
  
  const classes = useStyles();
  const [isConditionalForm, setIsConditionalForm] = useState(false);
  const [universalSelectorOptions, setUniversalSelectorOptions] = useState([]);

  useEffect(() => {
    formListService.getUniversalType().then((res) => {
      setUniversalSelectorOptions(res.data);
    });
  }, []);

  return (
    <Box p={3}>
      <div className={classes.spacing}>
        <Typography variant="h6" component="span" className={classes.condition}>
          DO YOU WANT TO MAKE THIS AS A CONDITIONAL FORM?
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            row
            value={isConditionalForm}
            onChange={(e) => setIsConditionalForm(e.target.value === "true")}
          >
            <FormControlLabel value={true} control={<Radio />} label={YES} />
            <FormControlLabel value={false} control={<Radio />} label={NO} />
          </RadioGroup>
        </FormControl>
      </div>
      {isConditionalForm && (
        <UniversalSelector
          universalSelectorOptions={universalSelectorOptions}
          createFormClicked={createFormClicked}
          onValidation={onValidation}
          setUniversalSelectorOptions={setUniversalSelectorOptions}
          updateForm={updateForm}
          copyForm={copyForm}
        />
      )}
    </Box>
  );
};

export default ConditionalForm;
