import './App.scss';
import '../shared/style/index.scss';
import 'react-toastify/dist/ReactToastify.css';
import React, { Component, Fragment } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../shared/theme/Theme';
import { ToastContainer } from 'react-toastify';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from '../route/Routes';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { UserInfoService } from '../services/user-info.service';
import FormBuilderService from '../services/form-builder.service';
import { loggedInState } from '../services/form.store';
import { AccessToken } from "../utility/Constant";
import { jwtDecode } from "jwt-decode";
import Logout from '../components/Logout';

const browserHistory = createBrowserHistory();

class App extends Component {
  constructor() {
    super();
    this.userInfoService = new UserInfoService();
    this.formBuilderService = new FormBuilderService();
    this.state = { userSSO: null, user: {}, userRole: '', isLoggedIn: false }
  }

  async getAccessToken(code) {
    let accessToken = '', tokenType = '', refreshToken = '';
	  let localRole = localStorage.getItem('user_role');
	  let userRole = (localRole && localRole.length > 0) ? 
		AccessToken.scope[localRole] : AccessToken.scope.TECHNICIAN;
      await this.formBuilderService.getAccessToken(code).then((response) => {
    	  if (response && response.status === 200) {
    		  accessToken = (response.data && response.data.access_token) ? 
    		      response.data.access_token : '';
          refreshToken = (response.data && response.data.refresh_token) ? 
          response.data.refresh_token : '';
    		  tokenType = (response.data && response.data.token_type) ? 
        		      response.data.token_type : '';
    		    if(accessToken && accessToken.length > 0) {
        		  localStorage.setItem('access_token', accessToken);
              localStorage.setItem('token_type', tokenType);
              localStorage.setItem('refresh_token',refreshToken);
              let decodedToken = JSON.stringify(jwtDecode(accessToken));
              let decodedSSO = JSON.parse(decodedToken).username.split("_")[1];
              localStorage.setItem('ge_sso', decodedSSO);
        	      this.userInfoService.getUserInfo(decodedSSO).then((response) => {
        	    	  const sso = response;
                    this.setState({
            	        userSSO: sso/* awaiting to get sso value from getUserInfo else we will get null value and we cannot able to view the application.*/
            	      }, () => {
            	        if(response){
                        this.setState({
                          user: response, 
                          userRole: response.userRole
                        }); 
                       }
            	      });
        	      });
        	  }
    	  }
          return accessToken;
      }).catch((error)=>{
        localStorage.clear()
        window.location.href = process.env.REACT_APP_SSO_LOGOUT;
      });
  }

  async componentDidMount() {
    var bool = false;
    this.loggedInSubscription = loggedInState.subscribe(isLoggedIn => {
      bool = isLoggedIn;
      this.setState({ isLoggedIn: bool});
    });
    if (!bool) this.loadUserDetails();
  }

  componentWillUnmount() {
    this.loggedInSubscription.unsubscribe();
  }

  loadUserDetails() {
     const logout = process.env.REACT_APP_SSO_LOGOUT;
      if(window.location != logout) {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get("code");
        if(code){
          this.getAccessToken(code);
        } else {
          window.location.href = process.env.REACT_APP_SSO_LOGIN;
        }
      }     
  }

  render() {
    return (
      <Fragment>
        {this.state.userSSO && this.state.isLoggedIn ?
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Router history={browserHistory}>
                <Routes userRole={this.state.userRole} />
              </Router>
            </MuiPickersUtilsProvider>
            <ToastContainer hideProgressBar={true} position='bottom-center' 
            	draggable={false} />
          </ThemeProvider>
          : <Logout/>}
      </Fragment>
    );
  }
}

export default App;