import {
  Box,
  Card,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useState, useEffect } from "react";
import { SELECT_TYPE } from "../utility/Constant";

export const FIRST_LEVEL = 1;
export const MAX_LEVELS = 4;

const UniversalSelector = ({ universalSelectorOptions, createFormClicked, onValidation, setUniversalSelectorOptions, copyForm, updateForm }) => {
  const useStyles = makeStyles(() => ({
    numberLabel: {
      fontSize: "14px",
      lineHeight: "28px",
    },
    addIcon: {
      color: "#419BAC",
      fontFamily: "Roboto",
      fontSize: "28px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      padding: "0",
    },
    iconButton: {
      padding: "0",
    },
  }));

  const classes = useStyles();
  const [universalName, setUniversalName] = useState("");
  const [universalSelectorData, setUniversalSelectorData] = useState([
    {
      universalType: SELECT_TYPE,
      uniqueCode: "",
      level: FIRST_LEVEL,
      error: false,
    },
  ]);

  useEffect(() => {
    setUniversalSelectorOptions((prevState) => {
      const selectedOptions = universalSelectorData?.map(
        (item) => item?.universalType
      );
      return prevState?.map((option) =>
        selectedOptions?.includes(option?.universalType)
          ? { ...option, hidden: true }
          : { ...option, hidden: false }
      );
    });
  }, [universalSelectorData, setUniversalSelectorOptions]);

  useEffect(() => {
    const isValid = universalSelectorData.every(
      (data) =>
        data.universalType !== 'Select Type' &&
        (data.universalType !== 'Others' || data.uniqueCode.trim() !== '')
    );
    onValidation(isValid);
  }, [createFormClicked, universalSelectorData, onValidation]);

  const updateUniversalSelectorData = (levelIdentifier, value) => {
    setUniversalSelectorData((prevState) => {
      const uniqueCodeValue = universalSelectorOptions.find(
        (option) => option.universalType === value
      )?.uniqueCode;
      const sameValueSelected = prevState?.some(
        (prev) =>
          prev?.level !== levelIdentifier && prev?.universalType === value
      );
      return prevState?.map((prev) =>
        prev?.level === levelIdentifier
          ? sameValueSelected
            ? {
                ...prev,
                universalType: SELECT_TYPE,
                uniqueCode: "",
                error: true,
              }
            : {
                ...prev,
                universalType: value,
                uniqueCode: uniqueCodeValue,
                error: false,
              }
          : prev
      );
    });
  };

  const addUniversalSelectorData = (lastLevel) => {
    setUniversalSelectorData((prevState) => {
      if (prevState.length < MAX_LEVELS) {
        return [
          ...prevState,
          {
            universalType: SELECT_TYPE,
            uniqueCode: "",
            level: lastLevel + 1,
            error: false,
          },
        ];
      }
      return prevState;
    });
  };

  const deleteUniversalSelectorData = (levelIdentifier, index) => {
    if (levelIdentifier === FIRST_LEVEL) {
      setUniversalSelectorData([
        {
          universalType: SELECT_TYPE,
          uniqueCode: "",
          level: FIRST_LEVEL,
          error: false,
        },
      ]);
    } else {
      setUniversalSelectorData((prev) => prev.slice(0, index));
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            id="universalName"
            label="Universal Name"
            name="universalName"
            value={universalName}
            onChange={(e) => setUniversalName(e.target.value)}
            required
            variant="outlined"
            style={{ marginTop: "12px" }}
          />
        </Grid>
      </Grid>
      <div style={{ paddingTop: "24px" }}>
        <Card>
          <Box p={4}>
            <Grid container spacing={3} pb={4}>
              {universalSelectorData.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={updateForm || copyForm ? 12 : 6}
                    container
                    spacing={2}
                    alignItems="center"
                    style={{ marginTop: index >= 2 ? "12px" : "0" }}
                  >
                    <Grid item>
                      <Typography
                        variant="h6"
                        align="center"
                        className={classes.numberLabel}
                      >
                        {item?.level}
                      </Typography>
                    </Grid>
                    <Grid item xs={5} style={{ marginRight: "13px" }}>
                      <TextField
                        select
                        fullWidth
                        variant="outlined"
                        value={item.universalType || ""}
                        onChange={(event) =>
                          updateUniversalSelectorData(
                            item?.level,
                            event.target.value
                          )
                        }
                        error={(createFormClicked && item.universalType === "Select Type") || item?.error}
                        helperText={createFormClicked && item.universalType === "Select Type" ? "Please select a type" : ''}
                      >
                        <MenuItem
                          value={SELECT_TYPE}
                          disabled
                          style={{ color: "#999" }}
                        >
                          {SELECT_TYPE}
                        </MenuItem>
                        {universalSelectorOptions.map(
                          ({ uniqueCode, universalType, hidden = false }) => (
                            <MenuItem key={uniqueCode} disabled={hidden} value={universalType}>
                              {universalType}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Unique Code"
                        value={item.uniqueCode}
                        onChange={(event) => {
                          if (item.universalType === "Others") {
                            const inputValue = event.target.value;
                            setUniversalSelectorData((prevState) =>
                              prevState?.map((prev) =>
                                prev?.level === item?.level
                                  ? { ...prev, uniqueCode: inputValue }
                                  : prev
                              )
                            );
                          }
                        }}
                        InputProps={{
                          readOnly: item.universalType !== "Others",
                        }}
                        error={
                          createFormClicked &&
                          item.universalType === 'Others' &&
                          item.uniqueCode.trim() === ''
                        }
                        helperText={
                          createFormClicked &&
                          item.universalType === 'Others' &&
                          item.uniqueCode.trim() === ''
                            ? 'Please enter a value'
                            : ''
                        }
                      ></TextField>
                    </Grid>
                    {universalSelectorData.length && (
                      <Grid item>
                        <IconButton                        
                          className={classes.iconButton}
                          onClick={() =>
                            deleteUniversalSelectorData(item?.level, index)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    )}
                    {item?.level === universalSelectorData.length &&
                      universalSelectorData.length < MAX_LEVELS &&
                      item.universalType !== SELECT_TYPE && (
                        <Grid>
                          <IconButton
                            className={classes.iconButton}
                            onClick={() => addUniversalSelectorData(item?.level)}
                          >
                            <AddIcon className={classes.addIcon} />
                          </IconButton>
                        </Grid>
                      )}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </Card>
      </div>
    </>
  );
};

export default UniversalSelector;
