import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import PanToolIcon from '@material-ui/icons/PanTool';
import Header from './Header';
import { currentUserState } from '../services/session.manager';
import Link from '@material-ui/core/Link';
import { withRouter } from 'react-router-dom';
import {SessionManager} from "../services/session.manager"

const styles = theme => ({
    root: { height: '100%', paddingTop: 64 },
    shiftContent: {
        minHeight: `calc(100% - 64px)`, display: "flex", alignItems: 'center',
        justifyContent: 'center', flexDirection: 'column'
    },
    rootCard: { width: '30%', height: '180px',border: '1px solid #9C9C9C' },
    pantoolIcon: { fontSize: '3.2rem', margin: '5%' },
    initialPoint: { paddingLeft: "8px" }
});

class Logout extends Component {
    constructor(props) {
        super(props);
    }

    handleLogIn = () => {
       SessionManager.sharedInstance().logIn();
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                { window.location == process.env.REACT_APP_SSO_LOGOUT ?
                <main className={classes.shiftContent}>
                    <Card className={classes.rootCard}>
                        <CardContent>
                            <Box>
                                <Grid container spacing={3}>
                                    
                                    <Grid item md={10} xs={10} className={classes.initialPoint}>
                                        <Typography gutterBottom variant="h6" component="h2">
                                            Single Sign on 
                                        </Typography>
                                        <Typography gutterBottom variant="h6" component="h2">
                                            Logout Complete
                                        </Typography>
                                        You have been logged out of this application.
                                        To completely logout,Please close this browser.
                                        
                                       
                                            <Link
                                                underline="always"
                                                id="login"
                                                component="button"
                                                variant="body2"
                                                onClick={this.handleLogIn}>
                                                Login
                                            </Link> 
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </main>
                : ''}
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(Logout));