
import React, { Component, Fragment } from 'react';
import { extractImageValue, extractRadioValue, extractCommentValue } from '../../store/form.answer.extract';
import { Avatar, Box, Card, FormControl, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import ImagePopup from './ImagePopup';
import { openSectionState, currentSectionState, formState } from '../../store/form.store';
import { radioButtonAnswer } from '../../store/form.answer.fill';
import { FORM_STATUS } from '../../utilities/Constants';

const styles = theme => ({
    root: {
        padding: theme.spacing(1),
        marginTop: 0,
    },
    commentText: {
        color: '#00000094',
        fontSize: '14px',
    },
});

class RadioButtonsGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            index: -1,
            imageOpen: false,
            imageValue: [],
            imageUrl: '',
            imageName: '',
            editable: false,
            commentValue: '',
            disabled:false
        };
    }

    componentDidMount() {
        let expanded = ''
        this.sectionStateSubscription = openSectionState.subscribe(sectionId => {expanded = sectionId});
        this.currentSectionSubscription = currentSectionState.subscribe(section => {
            this.setState({ editable: section === expanded})
        });
        this.onLoadPage(this.props);
        if (this.props.element.properties.allowFileUpload || this.props.element.properties.conditional) {
            this.onLoadImage(this.props);
        }
        if(this.props.element.properties.conditional){
            this.onLoadComment(this.props)
        }
        // Disable the radio group if the status is 'Completed' on the review page.
        // The 'Rejected', 'In-Progress', and 'Active' statuses will not allow the edit option for the entire section.
        this.sectionformDetails = formState.subscribe(item => {
            this.setState({ disabled: item.formStatus===FORM_STATUS.COMPLETED })
        });
    }

    componentWillUnmount() {
        this.sectionStateSubscription.unsubscribe();
        this.currentSectionSubscription.unsubscribe();
        this.sectionformDetails.unsubscribe();
    }

    onLoadPage(props) {
        if (props.element) {
            let userResponse = extractRadioValue(props.element.answer);
            if (userResponse) {
                this.setState({
                    value: userResponse,
                    index: props.element.options.indexOf(userResponse)
                });
            }
        }
    }

    onLoadImage(props) {
        if (props.element) {
            let image = props.element?.answer;
            let imageResponse = extractImageValue(image);
            if (imageResponse) {
                this.setState({
                    imageValue: imageResponse
                });
            }
        }
    }

    onLoadComment(props) {
        if (props.element) {
            let answer = props.element?.answer;
            let commentResponse = extractCommentValue(answer);
            if (commentResponse) {
                this.setState({
                    commentValue: commentResponse
                });
            }
        }
    }

    onClickAvatar = (image) => {
        this.setState({
            imageOpen: true,
            imageUrl: image.imageUrl,
            imageName: image.imageName
        });
    }

    onClose = () => {
        this.setState({ imageOpen: false });
    }

    onChange = (event) => {
        this.setState({
            value: event.target.name,
            index: event.target.value
        })
        if (this.props.onChange !== undefined) {
            this.props.onChange(radioButtonAnswer(event.target.name, this.state.commentValue, this.state.imageValue), this.props.element);
        }
    }

    render() {
        const { classes } = this.props;
        return (<Fragment>
            {this.state.editable ? (
                <FormControl component="fieldset">
                    <RadioGroup value={`${this.state.index}`} onChange={this.onChange}>
                        {this.props.element.options.map((option) => {
                            return (
                                <FormControlLabel
                                    name={option.value}
                                    key={option.id}
                                    value={option.value}
                                    control={<Radio color='primary' disabled={this.state.disabled} />} 
                                    label={option.value}
                                    checked={this.state.value === option.value ? true : false}
                                />
                            )
                        })}
                    </RadioGroup>
                    <FormHelperText>{this.props.element.properties.answerLabel}</FormHelperText>
                </FormControl>
            ) : (<Typography>{this.state.value}</Typography>)}
                        {((this.props.element.properties.allowFileUpload || this.props.element.properties.conditional) && this.state.imageValue.length>0) ?
            this.state.imageValue.map((image) => (
                <Card className={classes.root} >
                    <Box>
                        <Grid container spacing={1}>
                            <Avatar alt={image.imageName} src={image.imageUrl} variant="square" style={{ height: '75px', width: '100px' }}
                                onClick={() => this.onClickAvatar(image)} />
                        </Grid>
                    </Box>
                </Card>
            )) : null}
            <ImagePopup open={this.state.imageOpen} onClose={this.onClose} imageUrl={this.state.imageUrl} title={this.state.imageName}></ImagePopup>
            {(this.props.element.properties.conditional && this.state.commentValue !== "") ? <Typography className={classes.commentText}>{this.state.commentValue}</Typography> : null}
        </Fragment>);
    }
}
export default withStyles(styles)(RadioButtonsGroup);
