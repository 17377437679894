import React, { Fragment, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { createTheme } from '@material-ui/core/styles';
import { dateHelperService } from '../services/date-helper.service';
import { useHistory } from "react-router-dom";
import CopyFormDialog from '../views/CopyFormDialog';
import FormListActions from '../shared/component/FormListActions';
import { IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Box from '@material-ui/core/Box';
import { FormStore, dialogState } from '../services/form.store';
import AlertDialog from '../components/form-element/AlertDialog';
import { ALERT_DIALOGS, FORM_CATEGORIES } from '../services/form.helper.service';

const FormListView = props => {                
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [copyOpen, setCopyOpen] = React.useState(false);
    const [showCopyDialog, setShowCopyDialog] = React.useState(false);
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = React.useState(false);
    const [selectedForm, setSelectedForm] = React.useState(null); 
    const history = useHistory();

    useEffect(() => {
        dialogState.subscribe(val => { setShowCopyDialog(false); })
    }, []);

    const handleClick = (formId) => {            
        history.push('/editForm/' + formId);
    };

    const toggleActionMenu = () => {
        setCopyOpen((prevOpen) => !prevOpen);
    }

    const handleMoreClick = (e, item) => {        
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
        toggleActionMenu();
        setSelectedForm(item);
    }
    const handleClosecopyMenu = () => {            
        toggleActionMenu();                            
    }

    const handleCopy = () => {            
        toggleActionMenu();      
        if(selectedForm !== undefined) {
            const selectFormDetails = { ...selectedForm, title: `Copy of ${selectedForm.title}` }
            FormStore.sharedInstance().copyForm(selectFormDetails);
            toggleCopyFormDialog();
        } 
    }
    
    const handleDelete = () => {
        setShowDeleteConfirmDialog(true);
    }

    const handleCancelDelete = () => {
        setShowDeleteConfirmDialog(false);
        toggleActionMenu();
    }

    const handleConfirmDelete = () => {
        if (selectedForm !== undefined) {
            FormStore.sharedInstance().deleteForm(selectedForm.id);
            setShowDeleteConfirmDialog(false);
            toggleActionMenu();
        }
    }

    const handleCopyCancel = () => {  
        toggleCopyFormDialog();
    }

    const toggleCopyFormDialog = () => {
        setShowCopyDialog((prevCopyDialog) => !prevCopyDialog);
    }
    
    const getCategory = (form, name) =>{
        let filtered = form.formCategories.filter(formCategory=>formCategory.categoryType === name)
        if(filtered.length){
            return filtered[0].categoryName
        }else{
            return "NA";
        }
    }

    const onClosePopup = () => {
        setTimeout(() => {
          setCopyOpen(false);
        }, 5000);
      }
    
    return (
        <Fragment>
            {props.items.map(({ ...item }, index) => (
                <Card square variant="outlined" key={index}>
                    <CardActionArea component="div" disableRipple>
                        <CardContent>
                            <Box display="flex">
                                <Box onClick={(event) => handleClick(item.id)}>
                                    <Typography variant="h6" gutterBottom>
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        {item.languageName} &nbsp;·&nbsp; {item.version} &nbsp;·&nbsp; {item.formStatus} &nbsp;·&nbsp; {item.createdBy}
                                        &nbsp;·&nbsp; Last Edit: {dateHelperService.formattedDateFromUTS(item.lastUpdatedDate)}
                                        &nbsp;·&nbsp; {getCategory(item,FORM_CATEGORIES.BUSINESS)} &nbsp;/&nbsp; {getCategory(item,FORM_CATEGORIES.SUB_BUSINESS)} &nbsp;/&nbsp; {getCategory(item, FORM_CATEGORIES.GROUP)} &nbsp;/&nbsp; {getCategory(item,FORM_CATEGORIES.SUB_GROUP)}
                                    </Typography>
                                	<Typography component="div">
                                        {item.keywords.map((kValue, kIndex) => (
                                            <Chip variant="outlined" key={kIndex} 
                                            	label={kValue} size="small" />
                                        ))}
                                    </Typography>
                                </Box>
                                <Box alignSelf="center">
                                    <IconButton id="copyActions" edge="end" 
                                    	aria-label="more"aria-haspopup="true" 
                                    	aria-controls="long-menu" 
                                        onClick={(event) => handleMoreClick(event, item)} 
                                        onMouseLeave={onClosePopup}>
                                    	<MoreVertIcon />
                                    </IconButton> 
                                </Box>       
                            </Box>                                                        
                    </CardContent>
                </CardActionArea>
                </Card>
            ))}
            <FormListActions open={copyOpen} 
            	anchorEl={anchorEl} handleActionCancel={handleClosecopyMenu} 
            	handleCopy={handleCopy} handleDelete={handleDelete}
            	selectedForm={selectedForm} 
            	></FormListActions>
            <CopyFormDialog open={showCopyDialog} onClose={handleCopyCancel} />
            {(showDeleteConfirmDialog) ?
                <AlertDialog open={showDeleteConfirmDialog}
                    onClose={handleCancelDelete} handleOk={handleConfirmDelete}
                    dialogTitle={ALERT_DIALOGS.DELETE_FORM_TITLE}
                    dialogContent={ALERT_DIALOGS.DELETE_FORM_CONTENT}
                    cancelButton={ALERT_DIALOGS.DELETE_FORM_CANCEL}
                    confirmButton={ALERT_DIALOGS.DELETE_FORM_CONFIRM}
                /> : null}
        </Fragment>
    );
}

export default FormListView;