import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/styles';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import ActiveQuestionFooter from './ActiveQuestionFooter';
import ToolPallete from '../ToolPallete';
import FormElementTypeSelector from '../../FormElementTypeSelector';
import Components from '../CompMap';
import { FormStore, optionalEquipmentState } from '../../../services/form.store';
import FormDialog from '../SubSectionPopup';
import ConfirmationDialog from '../../form-element/ConfirmationDialog'
import { QuestionDeleteMsg } from '../../../utility/Constant'
import { FormInputTypesValidationService } from '../../../services/form-input-types-validations.service';
import { ATTRIBUTES } from '../../../services/form.helper.service';
import FormBuilderService from '../../../services/form-builder.service';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { Chip } from '@material-ui/core';
import { Loader } from '../../Loader';
import HelpImagesGroup from '../HelpImagesGroup';
import { DefaultElements } from '../ElementsDefault';

const styles = theme => ({
  root: {},
  activeTextField: { backgroundColor: 'rgba(255, 255, 255, .75)' },
  maxLenthStyle: { width: "249px" },
  error: { color: 'red', padding: '4px', fontSize: 14 },
  chip: { marginRight: '15px' },
  chipLabel: { fontSize: 12, marginLeft: '5px' },
  chipField: { margin: '5px' },
  grid: { width: '100%' },
  gridWidth: { width: 'calc(100% - 290px)' },
  elementGrid: { width: '290px' },
  textGrid: { width: '270px' },
  loaderBox: {position: 'relative', width: '20px', height: '20px', marginBottom: '10px',
    '& > .MuiCircularProgress-root': {
      width: '20px !important', height: '20px !important',
      },}
});

const ZERO_WIDTH_SPACE_CHAR = 8203;

const toSetDefaultEditorBreakTag = (item)=> {
  if(!item){
    return item
  }
  return item.replace(/<br\s*\/?>(<\/br>)?/g, '<br/>')
}
class ActiveQuestion extends Component {
  constructor(props) {
    super();
    if (props.elementPopup) {
      let componentType = 'questionType';
      this.elementId = props.elementPopup.elementId;
      this.state = {
        showError: false, elementPopup: props.elementPopup,
        text: toSetDefaultEditorBreakTag(props.elementPopup.label), componentPopup: componentType,
        allOptionalEquipments: []
      }
    } else {
      let answer = 'Help Text', disabled = true;
      let componentType = 'questionType';
      this.elementId = props.element.elementId;
      if (props.element.componentType !== 'helpimage' && 
    	  props.element.properties && props.element.properties.answerLabel) 
        answer = props.element.properties.answerLabel;
      if (props.element.componentType !== 'helpimage' && 
    	  props.element.properties && 
    	  props.element.properties.disabled !== undefined) 
        disabled = props.element.properties.disabled;
      this.state = {
        showError: false, element: props.element,
        text: toSetDefaultEditorBreakTag(props.element.label),
        showAnswerLabel: !disabled, answer: answer, showPopup: false,
        anchorEl: null, answerHintError: false, answerHintErrorText: '',
        component: componentType, hasOptionalEquipmentValue: '', allOptionalEquipments: [],
        showChip:false, questionErrorText : '',
        maxLength: (props.element.componentType === "text" && 
        		    props.element.properties.hasMaxLength) ? 
        		    	props.element.validations.maxLength : 10,
        maxLengthError: (props.element.componentType === "text" && 
        				 props.element.properties && 
        				 props.element.properties.hasMaxLength && 
        				 FormInputTypesValidationService.validateCommonAttributes(
        					ATTRIBUTES.MAX_LENGTH, props.element.validations.maxLength) !== '') ? 
        				true : false,
        maxLengthErrorText: (props.element.componentType === "text" && 
        					 props.element.properties && 
        					 props.element.properties.hasMaxLength) ? 
        						FormInputTypesValidationService.validateCommonAttributes(
        							ATTRIBUTES.MAX_LENGTH, props.element.validations.maxLength) : '',
        isComponentTypeChanged: props.element.componentType,
        helpImageValue: props.element.properties && props.element.properties.hasHelpImage
      }
    }
    this.formBuilderService = new FormBuilderService();
  }

  componentDidMount() {
    this.optionalEquipmentSubscription = optionalEquipmentState.subscribe(optionalEquipment => {
      this.setState({
        hasOptionalEquipmentValue: optionalEquipment,
        showChip: true,
      });
    });
    this.formBuilderService.getAllOptionalEquipments().then(response => {
      this.setState({
        allOptionalEquipments: response.data
      });
    });
  }

  componentDidUpdate(prevProps){
    if(prevProps.element.componentType !== this.state.isComponentTypeChanged) {
      this.setState({
        isComponentTypeChanged: this.props.element.componentType,
      })
      this.handleDeleteUniqueCode();
      FormStore.sharedInstance().removeAllHelpImage();
    }
   }

  componentWillReceiveProps() {
    if (this.props.elementPopup) {
      this.updateText(this.props.elementPopup.label);
    }
    else {
      this.updateText(this.getEditorDefaultValue(this.props.element.componentType));
    }
  }

  onAnswerChange = (event) => {
    const ansValue = event.target.value;
    let errorMessage = FormInputTypesValidationService.validateCommonAttributes(ATTRIBUTES.ANSWER_HINT, ansValue);
    if (errorMessage !== '') {
      this.setState({
        answerHintError: true,
        answerHintErrorText: errorMessage
      });
      FormStore.sharedInstance().addErrorId(this.elementId);
    }
    else {
      this.setState({
        answerHintError: false,
        answerHintErrorText: errorMessage
      });
      FormStore.sharedInstance().deleteErrorId(this.elementId);
    }
    this.setState({
      answer: ansValue
    });
    FormStore.sharedInstance().toggleQuestionAttr({ attr: 'properties', name: 'answerLabel', value: ansValue });
  }

  onMaxLengthChange = (event) => {
    const maxLength = event.target.value;
    let errorMessage = FormInputTypesValidationService.validateCommonAttributes(ATTRIBUTES.MAX_LENGTH, maxLength);
    if (errorMessage !== '') {
      if (!this.state.maxLengthError) { // Add only if the field is not already in error state
        FormStore.sharedInstance().addErrorId(this.elementId);
      }
      this.setState({
        maxLengthError: true,
        maxLengthErrorText: errorMessage,
      });
    }
    else {
      this.setState({
        maxLengthError: false,
        maxLengthErrorText: errorMessage,
      });
      FormStore.sharedInstance().deleteErrorId(this.elementId);
    }
    this.setState({
      maxLength: maxLength
    });
    FormStore.sharedInstance().toggleQuestionAttr({ attr: 'validations', name: 'maxLength', value: maxLength });
  }

  updateText = (text) => {
    this.setState({
      text: toSetDefaultEditorBreakTag(text)
    });
  }

  onBlur = (event) => {
    console.log('On Blur :' + event.target.value);
  }

  getAnswerLabelVisible = (value) => {
    this.setState({
      showAnswerLabel: value
    });
  }

  handleClosePopup = () => {
    this.setState({
      showPopup: false
    });
  };

  onIconClick = (value, event) => {
    this.iconValue = value;
    if (value === 'TYPE_COPY' || value === 'TYPE_MOVE') {
      this.setState({
        showPopup: true
      });
    }
    else if (value === 'TYPE_DELETE') {
      this.setState({ anchorEl: event.currentTarget });
    }
    else {
      this.setState({
        showPopup: false
      });
    }
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  deleteQuestion = () => {
    FormStore.sharedInstance().deleteElement(this.props.element, this.props.subsectionId);
    this.handleClose();
  }

  onTitleChange = (event) => {
    const newValue = event.target.value;
    if (newValue.length <= 0) {
      this.setState({
        showError: true
      });
    }
    this.props.elementPopup.label = newValue;
    this.updateText(newValue);
  }

  getEditorDefaultValue(componentType) {
    return DefaultElements[componentType].label 
  }

  onChangeRTE = (text) => {
    let formattedText = text.split('').map((char) => char.charCodeAt(0) !== ZERO_WIDTH_SPACE_CHAR ? char : '').join('').replace(/<br\s*\/?>/g, '<br></br>');
    let enteredValue = formattedText;
    const plainText = enteredValue.replace(/(<([^>]+)>)/ig, '');
    this.setState({
      text: formattedText
    });
    let errorMessage = FormInputTypesValidationService.validateCommonAttributes(ATTRIBUTES.QUESTION_LABEL, plainText);
    if (errorMessage !== '') {
      this.setState({
        showError: true,
        questionErrorText: errorMessage
      });
      FormStore.sharedInstance().addErrorId(this.elementId);
    }
    else {
      this.setState({
        showError: false,
        questionErrorText: errorMessage
      });
      FormStore.sharedInstance().deleteErrorId(this.elementId);
    }
    this.props.element.label = enteredValue;
    this.updateText(enteredValue);
    FormStore.sharedInstance().shouldNavigate(true);
  }

  handleDelete = () => {
    FormStore.sharedInstance().toggleQuestionAttr({ attr: 'properties', name: ATTRIBUTES.HAS_OPTIONAL_EQUIPMENT_VALUE, value: '' });
    FormStore.sharedInstance().getOptionalEquipment('');
    this.setState({
      showChip: false
    });
  }

  handleDeleteUniqueCode = () => {
    FormStore.sharedInstance().deleteUniqueCodeFromQuestion();
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        {this.props.element ?
          <Box p={3} >
            <Grid container spacing={3}>
              <Grid item className={classes.grid} >
                <ToolPallete id="questionIcon" 
                	type={ToolPallete.PalleteType.QUESTION_TYPE} 
                	onClick={this.onIconClick} />
                <ConfirmationDialog open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose} title="Delete Question"
                  description={QuestionDeleteMsg} onDelete={this.deleteQuestion}
                  anchorEl={this.state.anchorEl} />
              </Grid>
              <Grid item className={classes.gridWidth} >
              <SunEditor key={this.props.element.componentType} defaultValue={this.state.text}
                  height='auto' minHeight='50' 
                  setOptions={{
                    buttonList: [
                      [
                        'bold', 'italic', 'underline', 'list', 'align', 
                        'fontColor', 'hiliteColor'
                      ]
                    ],
                    colorList: [
                      [
                        '#ff0000', '#ff5e00', '#ffe400', '#abf200', '#00d8ff', '#ffffff',
                        '#0055ff', '#6600ff', '#ff00dd', '#000000'
                      ]
                    ],
                    showPathLabel: false, resizingBar: false
                  }}
                  onChange={this.onChangeRTE}/>
                {(this.state.showError && this.state.questionErrorText.length) ?
                	<label className={classes.error}>
                		{this.state.questionErrorText}</label> : null}
              </Grid>

              <Grid item className={classes.elementGrid}>
                <RenderFormElementType element={this.props.element} />
              </Grid>

              {(this.props.element.componentType !== 'helpimage' && this.props.element.properties) ?
              (this.props.element.componentType !== 'radio' && !this.props.element.properties.disabled) ||
              (this.props.element.componentType === 'radio' && this.props.element.properties.disabled) ?
                <Grid item className={classes.gridWidth} >
                  <TextField id="answer_text" fullWidth required type="text"
                    label="Help Text" variant="outlined"
                    error={this.state.answerHintError}
                    helperText={this.state.answerHintErrorText}
                    value={this.state.answer} onChange={this.onAnswerChange}
                    className={classes.activeTextField} />
                </Grid> : null: null}

              {(this.props.element.componentType === "text" && 
            	this.props.element.properties && 
            	this.props.element.properties.hasMaxLength) ?
                <Grid item className={classes.textGrid} >
                  <TextField id="maxLength" fullWidth required type="number"
                    label="Max Length" variant="outlined"
                    error={this.state.maxLengthError}
                    helperText={this.state.maxLengthErrorText}
                    value={this.state.maxLength}
                    onChange={this.onMaxLengthChange}
                    className={classes.activeTextField} />
                </Grid> : null}

              {(this.state.showPopup) ? 
            	<FormDialog id="ringtone-menu" 
            		type={(this.iconValue === "TYPE_COPY") ? "copy" : "move"}
                	keepMounted open={this.state.showPopup} 
              		element={this.props.element} onClose={this.handleClosePopup} 
              		component={this.state.component} /> 
              : null}
              <Grid item className={classes.grid} >
                <RenderElement id="renderElement" 
                	element={this.props.element} />
              </Grid>
              {this.props.element.properties && 
               this.props.element.properties.hasOptionalEquipmentValue && 
               this.props.element.properties.hasOptionalEquipmentValue !== '' &&
               <Typography component="div" className={classes.chip}>
                  <Typography className={classes.chipLabel}>
                  	OPTIONAL EQUIPMENT</Typography>
                  {(this.props.element.properties && 
                	this.props.element.properties.hasOptionalEquipmentValue && 
                  this.state.showChip && this.state.allOptionalEquipments.length > 0) ?
                    <Chip color="secondary" onDelete={this.handleDelete} 
                  		key="logic" className={classes.chipField} 
                  		label={this.props.element.properties.hasOptionalEquipmentValue ? 
                        this.state.allOptionalEquipments.length > 0 && this.state.allOptionalEquipments.filter(item => (item.optionalEquipId).toString() === (this.props.element.properties.hasOptionalEquipmentValue).toString())[0].equipmentName : this.state.hasOptionalEquipmentValue} size="small" />
                    : <div className={classes.loaderBox}><Loader position="center" loading={true} /></div>}
               </Typography>
              }
              {this.props.element.uniqueCode &&
                <Typography component="div" className={classes.chip}>
                  <Typography className={classes.chipLabel}>
                  	UNIQUE ID</Typography>
                    <Chip color="secondary" 
                    	onDelete={this.handleDeleteUniqueCode} key="logic" 
                    	className={classes.chipField} 
                    	label={this.props.element.uniqueCode.labelInReport} 
                    	size="small" />
                </Typography>
              }
            </Grid>
          </Box> : null}
          {this.props.element?.properties?.hasHelpImage && (
          <HelpImagesGroup
            elementHasId={this.props.element.id}
            propsimagesData={this.props.element.imagesData}
            helpImageValue={this.state.helpImageValue}
          />
        )}
        {this.props.element ? 
        	<ActiveQuestionFooter id="footer" element={this.props.element} 
        		answerText={this.getAnswerLabelVisible} answer={this.state.answer} /> 
        : null}

        {this.props.elementPopup ?
          <Box p={3} >
            <Grid container spacing={3}>
              <Grid item className={classes.grid} >
                <TextField id="question_text" fullWidth required 
                  variant="outlined" value={this.state.text}
                  onChange={this.onTitleChange}
                  className={classes.activeTextField} />
                {this.state.showError ? 
                	<label id="questionLabel" className={classes.error}>
                		Question Text is Required! </label> 
                 : null}
              </Grid>


              <Grid item className={classes.grid} >
                <RenderElement id="renderElementPopup" 
                	elementPopup={this.props.elementPopup} />
              </Grid>
            </Grid>
          </Box> : null}
      </Fragment>
    )
  }
}

function RenderElement(props) {
  if (props.elementPopup) {
    if (props.elementPopup !== undefined) {
      if (props.elementPopup.componentType !== undefined) {
        if (props.elementPopup.componentType === 'radio' || 
        	props.elementPopup.componentType === 'checkbox' || 
        	props.elementPopup.componentType === 'select') {
          const Element = Components[props.elementPopup.componentType];
          return <Element elementPopup={props.elementPopup} />
        } else {
          return null;
        }
      }
    }
  } else {
    if (props.element !== undefined) {
      if (props.element.componentType !== undefined) {
        const Element = Components[props.element.componentType];
        return <Element element={props.element} />
      }
    }
  }
  return (<div> Invaild component type </div>);
}

function RenderFormElementType(props) {
  var type = "Unknow";
  if (props.element !== undefined && 
	  props.element.componentType !== 'subsection') {
    if (props.element.componentType !== undefined) 
    	type = props.element.componentType;
  }
  return <FormElementTypeSelector type={type} element={props.element} />
}

export default withStyles(styles)(ActiveQuestion);