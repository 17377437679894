import React,{useState,useEffect} from 'react';
import { Route, Redirect ,useHistory} from 'react-router-dom';
import FormBuilderService from '../services/form-builder.service';
import PropTypes from 'prop-types';
import { SessionManager } from '../services/session.manager';

const RouteWithLayout = props => {
    const { layout: Layout, component: Component, ...rest } = props;
    const [isSessionValid, setisSessionValid] = useState(null);
    const history = useHistory();
    const userHasRequiredRole = props.userRole && props.roles.includes(props.userRole) ? true : false;
    useEffect(() => {
        SessionManager.sharedInstance().isValidSession().then(isValid => {
            setisSessionValid(isValid); 
            if(!isValid){
                history.push('/logout')
            }
        }).catch(error => {
            history.push('/logout')
        });
    }, []);

    if (userHasRequiredRole ) {
        return (
            <Route
                {...rest}
                render={matchProps => (
                    isSessionValid ? (
                    <Layout userRole={props.userRole}>
                        <Component {...matchProps} />
                    </Layout>
                    ) : null
                )}
            />

        );
    }
    else {
        return <Redirect exact from={props.location.pathname} to="/accessDenied"/>
    }
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;
