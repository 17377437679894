import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    flexGrow: {
      flexGrow: 1,
    },
  });
  
class DialogFooter extends Component {
  render() {
    const { classes } = this.props;
    const disabled=(this.props.disabled)?!this.props.disabled():null;
    return (<Toolbar id="toolar">
        <span className={classes.flexGrow} />
        <Button variant="outlined" color="secondary"  onClick={this.props.cancel}>Cancel</Button>
        {(this.props.copyForm)?
        <Button variant="contained" disableElevation disabled={disabled} onClick={this.props.save}>Copy Form</Button>
        :
        <Button variant="contained" disableElevation disabled={disabled} onClick={this.props.save}>Update</Button>
        }        
      </Toolbar>);
  }

}
export default withStyles(styles, { withTheme: true })(DialogFooter);
