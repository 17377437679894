import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
   deleteButton:{
    border: '1px solid #FE1919', 
    color:"white",
    backgroundColor:"#FE1919",
    '&:hover': { 
      backgroundColor: '#FE1919' 
    } 

   }
}))

export default function AlertDialog(props) {
  const { onClose, open ,handleOk, dialogTitle, dialogContent, cancelButton, confirmButton} = props;
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           {dialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary" variant="outlined">
            {cancelButton}
          </Button>
          <Button onClick={handleOk} className={classes.deleteButton} variant="outlined" autoFocus>
            {confirmButton}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
